import React from 'react';
import { RichText } from 'prismic-reactjs';

import TwitterIcon from '../../images/icon-twitter-alt.svg';
import YoutubeIcon from '../../images/icon-youtube-alt.svg';
import FacebookIcon from '../../images/icon-facebook-alt.svg';
import InstagramIcon from '../../images/icon-instagram-alt.svg';

const StayConnected = ({ title, description }) => {
  return (
    <div
      style={{
        backgroundColor: '#0A0A0A',
      }}
    >
      <div className="stay-connected">
        <h2>{title}</h2>

        <div className="stay-connected-description">
          <RichText render={description} />
        </div>

        <div className="social-wrapper">
          <a href="https://www.facebook.com/lineagejourney/" target="__blank">
            <img src={FacebookIcon} />
          </a>
          <a href="https://www.instagram.com/lineagejourney/" target="__blank">
            <img src={InstagramIcon} />
          </a>
          <a href="https://twitter.com/lineagejourney" target="__blank">
            <img src={TwitterIcon} />
          </a>
          <a href="https://www.youtube.com/channel/UChwz5_GZN655houtFoVKQ7w" target="__blank">
            <img src={YoutubeIcon} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default StayConnected;
